.App {
  text-align: center;
  background-color: #4db5ed; /* Sets the background color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Title {
  font-family: 'Pacifico', cursive; /* New stylish cursive font */
  color: #FFFFFF; /* White color for the text */
  font-size: 4rem; /* Increased text size for more impact */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle text shadow for depth */
  margin-bottom: 20px; /* Space between the title and the image */
  animation: pulse 2s infinite; /* Optional: Adds a gentle pulse animation */
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.Construction-image {
  max-width: 600px; /* Maximum width of the image */
  height: auto; /* Height automatically adjusts */
  width: calc(100% - 64px); /* Ensure width is 100% minus 64px for margins */
  margin: 0 32px; /* Margin of 32px on left and right */
}
